<template>
  <div class="container">
    <div class="float_right">
      <p><a @click="()=>{
            contentHtml = textInfo.rule
            contentHtmlModal = true
          }" class="link">规则 > </a></p>
      <router-link to="/service"><img :src="oss+'kefu2.png'" class="kefu"/></router-link>

    </div>

    <div class="cover-image"><img :src="oss+'6284657fbea95.jpg'"/></div>
    <div class="main">

      <div class="mobile-form">

        <van-count-down millisecond :time="time" class="count-down">
          <template #default="timeData">
            <span class="text">还剩</span>
            <span class="block">{{ timeData.minutes }}</span>
            <span class="colon">:</span>
            <span class="block">{{ timeData.seconds }}</span>
            <span class="colon">:</span>
            <span class="block">{{moment(timeData.milliseconds).format("SS")}}</span>
            <span class="text">过期</span>
          </template>
        </van-count-down>

        <div class="phone">
          <van-field v-model="json.phone" type="tel" :border="false" ref="phone" placeholder="请输入您的手机号码">
            <template #left-icon>
              <van-icon class-prefix="icon" name="mobile" size="24" color="#f60"/>
            </template>
          </van-field>
        </div>
        <div class="paybtn"><van-button round block @click="onsubmit">确认支付</van-button></div>

        <div class="tips">
          <van-checkbox v-model="isread" icon-size="16px" checked-color="#f60">我已阅读并同意</van-checkbox>
          <a @click="()=>{
            contentHtml = textInfo.privacy_policy
            contentHtmlModal = true
          }">《用户隐私协议》</a>
          <a @click="()=>{
            contentHtml = textInfo.user_agreement
            contentHtmlModal = true
          }">《用户协议》</a>
        </div>

      </div>

      <div class="good"><img :src="oss+'62d91ff291b03.jpg'" width="100%"/></div>
    </div>

    <div class="foot">
      <p>客服电话：4006178158</p>
      <p>{{textInfo.company_name}}</p>
    </div>

    <van-popup v-if="contentHtmlModal" v-model="contentHtmlModal" :overlay-style="{backgroundColor:'rgba(0,0,0,.5)'}" :close-on-click-overlay="false" class="contentPop">
      <div class="html" v-html="contentHtml"></div>
      <div class="close"><van-button type="primary" round color="#f60" style="width:60%" @click="()=>{contentHtmlModal = false}">确定</van-button></div>
    </van-popup>

    <payment :data="json" :money="money" ref="payment" v-if="money.price" :showRetention="1"/>
  </div>
</template>
<script>
  import moment from 'moment'
  import payment from './components/payment'
  import {GetPayConfig,GetUserPrivate} from './services/services'
  export default {
    name:'payv12',
    components: {payment},
    data () {
      return {
        time:1000*60*10,
        money:{},
        json:{
          id:0,
          pay_way:0,
          order_sn:'',
          phone:'',
          account:'',
          url:location.href
        },
        detail:{},
        contentHtmlModal:false,
        contentHtml:'',
        isread:true,
      }
    },
    created(){
      const {phone} = this.$route.query
      this.json={
        ...this.json,
        ...this.$route.query,
        phone:(phone==undefined?'':atob(phone))
      }

      this.type = this.json.type==undefined ? undefined:this.json.type
    },
    mounted(){
      this.findpayConfig()
      this.finduserprivate()
    },
    watch:{
      'json.phone':{
        handler:function(value){
          if(value.length == 11){
            if(!/^1[3456789]\d{9}$/.test(value)) {
              this.$toast('请输入正确的手机号码')
              this.$refs.phone.focus()
            }else{
              this.$refs.phone.blur()
            }
          }
        },
        deep:true
      }
    },
    methods:{
      moment,
      findpayConfig(){
        GetPayConfig({type:this.type}).then(result => {
          if(result.data.code==1){
            this.detail = this.detail = result.data.data
            let payWay = this.detail.payConfig[0].payWay
            let pay=this.detail.payConfig.find(item=>item.payWay == payWay)
            const {pwyAccount} = pay
            this.json.pay_way=payWay
            this.json.account=pwyAccount

            let money=this.detail.goodsArray.find(item=>item.status==1)
            this.money=money

            this.json.id=money.id

            const {app_download_url,kefu_url} = this.detail
            let data = {app_download_url,kefu_url}
            sessionStorage.setItem('payConfig',JSON.stringify(data))
          }else{
            this.$toast.fail(result.data.msg)
          }
        })
      },
      finduserprivate(){
        GetUserPrivate().then(result => {
          if(result.data.code==1){
            this.textInfo = result.data.data
          }else{
            this.$toast.fail(result.data.msg)
          }
        })
      },
      onsubmit(){
        if(!this.isread){
          this.$toast('请阅读隐私协议与用户协议')
          return false
        }
        if(this.json.phone==''){
          this.$toast('请输入充值手机号')
          this.$refs.phone.focus()
          return false
        }else if(!/^1[3456789]\d{9}$/.test(this.json.phone)){
          this.$toast('请输入正确的手机号码')
          this.$refs.phone.focus()
          return false
        }
        this.$refs.payment.onsubmit(this.json)

      }

    }
  }

</script>
<style scoped lang="less">
  .container{min-height:100vh;background:#fb4716;position:relative;

    .cover-image{
      img{width:100%;}
    }
    .main{margin:0px 15px 0px;
      .mobile-form{padding:30px 26px;background:#fff;border-radius:8px;

        .count-down{text-align:center;
          .colon{line-height:24px;padding:0px 5px;color:#ff3a0e;}
          .block{display:inline-block;text-align: center;width:30px;height:28px;line-height:28px;border-radius:2px;background-color:#ff3a0e;box-sizing:border-box;color:#fff;}
          .text{padding:0px 6px;color:#333;font-size:16px;}
        }
        .phone{margin-top:24px;
          .van-cell{border-radius:4px;border:1px solid #f60;font-size:20px;line-height:30px;padding:10px 10px 8px 10px;
            /deep/.van-field__body{
              .van-field__control{color:#f60 !important;}
              input::-webkit-input-placeholder{
                color: #f60;
              }
              input:-moz-placeholder{
                color: #f60;
              }
              input::-moz-placeholder{
                color: #f60;
              }
              input:-ms-input-placeholder{
                color: #f60;
              }
            }
          }
        }
        .paybtn{margin-top:20px;
          .van-button{-webkit-animation:paybtn_scalc 1s ease infinite;animation:paybtn_scalc 1s ease infinite;background:linear-gradient(to bottom,#ffa100,#ff2204);color:#fff;border:none;height:50px;line-height:50px;
            .van-button__text{font-size:20px;}
          }
        }
        .tips{margin-top:20px;font-size:12px;display:flex;
          span{display:inline-block;}
          a{color:#f60;}
          /deep/.van-checkbox__label{font-size:12px;color:#f60;}
        }
      }
      .good{margin-top:12px;background:#fff;border-radius:8px;overflow:hidden;
        img{}
      }
    }
    .foot{color:#fff;text-align:center;line-height:24px;padding:20px 0px;}
    .content{color:#999;padding:20px;font-size:12px;
      /deep/p{padding:4px 0px;}
    }
  }

  @keyframes paybtn_scalc{
    0% {
      -webkit-transform:scale(0.9);
      transform: scale(0.9);
    }
    50% {
      -webkit-transform:scale(1.1);
      transform: scale(1.1);
    }
    100% {
      -webkit-transform:scale(0.9);
      transform: scale(0.9);
    }
  }
</style>
