var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"float_right"},[_c('p',[_c('a',{staticClass:"link",on:{"click":()=>{
          _vm.contentHtml = _vm.textInfo.rule
          _vm.contentHtmlModal = true
        }}},[_vm._v("规则 > ")])]),_c('router-link',{attrs:{"to":"/service"}},[_c('img',{staticClass:"kefu",attrs:{"src":_vm.oss+'kefu2.png'}})])],1),_c('div',{staticClass:"cover-image"},[_c('img',{attrs:{"src":_vm.oss+'6284657fbea95.jpg'}})]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"mobile-form"},[_c('van-count-down',{staticClass:"count-down",attrs:{"millisecond":"","time":_vm.time},scopedSlots:_vm._u([{key:"default",fn:function(timeData){return [_c('span',{staticClass:"text"},[_vm._v("还剩")]),_c('span',{staticClass:"block"},[_vm._v(_vm._s(timeData.minutes))]),_c('span',{staticClass:"colon"},[_vm._v(":")]),_c('span',{staticClass:"block"},[_vm._v(_vm._s(timeData.seconds))]),_c('span',{staticClass:"colon"},[_vm._v(":")]),_c('span',{staticClass:"block"},[_vm._v(_vm._s(_vm.moment(timeData.milliseconds).format("SS")))]),_c('span',{staticClass:"text"},[_vm._v("过期")])]}}])}),_c('div',{staticClass:"phone"},[_c('van-field',{ref:"phone",attrs:{"type":"tel","border":false,"placeholder":"请输入您的手机号码"},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('van-icon',{attrs:{"class-prefix":"icon","name":"mobile","size":"24","color":"#f60"}})]},proxy:true}]),model:{value:(_vm.json.phone),callback:function ($$v) {_vm.$set(_vm.json, "phone", $$v)},expression:"json.phone"}})],1),_c('div',{staticClass:"paybtn"},[_c('van-button',{attrs:{"round":"","block":""},on:{"click":_vm.onsubmit}},[_vm._v("确认支付")])],1),_c('div',{staticClass:"tips"},[_c('van-checkbox',{attrs:{"icon-size":"16px","checked-color":"#f60"},model:{value:(_vm.isread),callback:function ($$v) {_vm.isread=$$v},expression:"isread"}},[_vm._v("我已阅读并同意")]),_c('a',{on:{"click":()=>{
          _vm.contentHtml = _vm.textInfo.privacy_policy
          _vm.contentHtmlModal = true
        }}},[_vm._v("《用户隐私协议》")]),_c('a',{on:{"click":()=>{
          _vm.contentHtml = _vm.textInfo.user_agreement
          _vm.contentHtmlModal = true
        }}},[_vm._v("《用户协议》")])],1)],1),_c('div',{staticClass:"good"},[_c('img',{attrs:{"src":_vm.oss+'62d91ff291b03.jpg',"width":"100%"}})])]),_c('div',{staticClass:"foot"},[_c('p',[_vm._v("客服电话：4006178158")]),_c('p',[_vm._v(_vm._s(_vm.textInfo.company_name))])]),(_vm.contentHtmlModal)?_c('van-popup',{staticClass:"contentPop",attrs:{"overlay-style":{backgroundColor:'rgba(0,0,0,.5)'},"close-on-click-overlay":false},model:{value:(_vm.contentHtmlModal),callback:function ($$v) {_vm.contentHtmlModal=$$v},expression:"contentHtmlModal"}},[_c('div',{staticClass:"html",domProps:{"innerHTML":_vm._s(_vm.contentHtml)}}),_c('div',{staticClass:"close"},[_c('van-button',{staticStyle:{"width":"60%"},attrs:{"type":"primary","round":"","color":"#f60"},on:{"click":()=>{_vm.contentHtmlModal = false}}},[_vm._v("确定")])],1)]):_vm._e(),(_vm.money.price)?_c('payment',{ref:"payment",attrs:{"data":_vm.json,"money":_vm.money,"showRetention":1}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }